import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FocusTrap from 'focus-trap-react';
import InputSearch from './InputSearch';
import SearchTray from './SearchTray';
import { nanoid } from 'nanoid';
import MainHeaderHamburgerMenu from './MainHeaderHamburgerMenu';
import BrowserWarning from './BrowserWarning';

const MainHeader = ({   
  exampleSuggestions = [],
  inputSearchLabel = '',
  links = [],
  logoLinks = { standard: '', mobile: '' },
  logoHref = '/',
  name = '',
  qaEnvironment = false,
  showOuterLinks = false,
  showLogin = false
}) => {
  const [searchOpen, setSearchOpen] = useState(false);
  const [hamburgerMenuOpen, setHamburgerMenuOpen] = useState(false);

  useEffect(() => {
    const handleClose = e => {
      if (e.key === 'Escape') {
        setSearchOpen(false);
      }
    };
    if (searchOpen) {
      document.addEventListener('keydown', handleClose);
    } else {
      document.removeEventListener('keydown', handleClose);
    }
    return () => {
      document.removeEventListener('keydown', handleClose);
    };
  }, [searchOpen]);

  useEffect(() => {
    const handleClose = e => {
      if (e.key === 'Escape') {
        setHamburgerMenuOpen(false);
      }
    };

    if (hamburgerMenuOpen) {
      document.addEventListener('keydown', handleClose);
    } else {
      document.removeEventListener('keydown', handleClose);
    }
    return () => {
      document.removeEventListener('keydown', handleClose);
    };
  }, [hamburgerMenuOpen]);

  function toggleSearch() {
    setSearchOpen(!searchOpen);
    setHamburgerMenuOpen(false);

    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  }

  const { standard, mobile } = logoLinks || { standard: '', mobile: '' };

  return (
    <header className={`${name === "Helsedirektoratet" ? 'b-main-header__wrapper' : ''}`}>
      <BrowserWarning />
      {searchOpen && (
        <SearchTray
          open={searchOpen}
          id="searchTray"
          role="region"
          aria-live="polite"
        >
          <div className="l-container">
            <InputSearch
              dark
              autoFocus={searchOpen}
              label={inputSearchLabel}
              exampleSuggestions={exampleSuggestions}
            />
          </div>
        </SearchTray>
      )}
      <FocusTrap active={hamburgerMenuOpen}>
        <div className="l-container">
          <div
            className={`b-main-header ${
              qaEnvironment ? 'b-main-header--qa' : ''
            } ${
              name === "Helfo" ? 'b-main-header--border' : ''
            }`}
          >
            <a
              href={logoHref}
              className="b-main-header__logo-link"
              aria-label={`${name} logo`}
            >
              <div className="l-hide-to-md">
                <div
                  className={`b-main-header__logo ${
                    qaEnvironment ? 'b-main-header__logo--qa' : ''
                  }`}
                  style={
                    standard ? { 'backgroundImage': `url(${standard})` } : {}
                  }
                >
                </div>
              </div>
              <div className="l-hide-from-md">
                <div
                  className="b-main-header__logo b-main-header__logo--small"
                  style={mobile ? { 'backgroundImage': `url(${mobile})` } : {}}
                  aria-label={`${name} logo mobil`}
                >
                  {name}
                </div>
              </div>
            </a>

            <div className="b-main-header__buttons-right">
              <div className="l-hide-to-lg">
                {!searchOpen && (
                  <button
                    onClick={toggleSearch}
                    className={`b-button b-button--small b-button--search ${
                      name === "Helsedirektoratet" 
                        ? 'b-button--secondary-dark-filled' 
                        : 'b-button--secondary-dark'
                    }`}
                    aria-controls="searchTray"
                  >
                    Søk
                  </button>
                )}
                {searchOpen && (
                  <button
                    onClick={toggleSearch}
                    className={`b-button b-button--small ${
                      name === "Helsedirektoratet" 
                      ? 'b-button--secondary-dark-filled' 
                      : 'b-button--secondary-dark'
                    }`}
                  >
                    Lukk søk
                  </button>
                )}
              </div>

              <div className={showOuterLinks ? 'l-hide-from-lg' : ''}>
                <nav
                  className="b-main-header__nav"
                  role="navigation"
                  aria-label="Hovedmeny mobil"
                >
                  <button
                    onClick={() => {
                      setHamburgerMenuOpen(!hamburgerMenuOpen);
                      setSearchOpen(false);
                    }}
                    className={`l-ml-1 b-button b-button--small ${
                      hamburgerMenuOpen
                        ? 'b-button--hamburger-close'
                        : 'b-button--hamburger'
                      } ${
                      name === "Helsedirektoratet" 
                          ? 'b-button--secondary-dark-filled' 
                          : 'b-button--secondary-dark'
                    }`}
                    aria-controls="mobile-menu"
                    aria-expanded={hamburgerMenuOpen ? 'true' : 'false'}
                    aria-haspopup="true"
                  >
                    Meny
                  </button>
                </nav>
              </div>

              <MainHeaderHamburgerMenu
                name={name}
                logoLinks={logoLinks}
                logoHref={logoHref}
                hamburgerMenuOpen={hamburgerMenuOpen}
                setHamburgerMenuOpen={setHamburgerMenuOpen}
                links={links}
                toggleSearch={toggleSearch}
                searchOpen={searchOpen}
                qaEnvironment={qaEnvironment}
                showLogin={showLogin}
                exampleSuggestions={exampleSuggestions}
              />

              {showOuterLinks && (
                <div className="l-hide-to-lg">
                  <nav
                    className="b-main-header__nav"
                    role="navigation"
                    aria-label="Hovedmeny"
                  >
                    {links.map(link => (
                      <a
                        href={link.href}
                        className="b-main-header__link"
                        key={nanoid()}
                      >
                        {link.name}
                      </a>
                    ))}
                  </nav>
                </div>
              )}
            </div>

            {showLogin && (
              <button 
                className={`l-ml-1 b-button b-button--small ${
                  name === "Helsedirektoratet" 
                    ? 'b-button--secondary-dark-filled' 
                    : 'b-button--secondary-dark'
                }`}>
                Logg inn
              </button>
            )}
          </div>
        </div>
      </FocusTrap>
    </header>
  );
};

MainHeader.propTypes = {
  exampleSuggestions: PropTypes.arrayOf(PropTypes.string),
  inputSearchLabel: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.object),
  logoHref: PropTypes.string,
  name: PropTypes.string,
  qaEnvironment: PropTypes.bool,
  showOuterLinks: PropTypes.bool,
  showLogin: PropTypes.bool
};

export default MainHeader;
