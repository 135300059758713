/*
  ~ ~ ~ IMPORTANT ~ ~ ~

  This component is NOT part of a React application made available to the backend!
  It should therefore have no conditional logic related to rendering - as it does now.

  That kind of logic is currently applied through vanilla javascript, here:
  https://github.com/netliferesearch/hdir-vanilla-js/blob/master/src/collapsible.js

*/

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Alert from './Alert';
import Heading from './Heading';

const buttonClasses = (active, size, tag, button, primary, arrowLeft) =>
  classNames({
    'b-collapsible__button': true,
    'b-collapsible__button--active': active,
    'b-collapsible__button--tag': tag,
    'b-collapsible__button--tiny': size === 'tiny',
    'b-collapsible__button--small': size === 'small',
    'b-collapsible__button--arrow-left': arrowLeft,
    'b-collapsible__button--medium': size === 'medium',
    'b-collapsible__button--secondary-dark': size === 'primary',
    'b-button b-button--secondary': button,
    'b-button b-button--primary': primary
  });

const headingClasses = (size, bold) =>
  classNames({
    'b-collapsible__heading': true,
    h2: size === 'large',
    h3: size === 'medium',
    normal: size === 'small' || size === 'tiny',
    // These additional 'bold' classes are here because HDIR wants to keep semantic choices whilst
    //  still having control over 'bold' styles on headings.
    //  Note that one has to explicitly specify if the 'bold' prop is false. Leaving it undefined
    //  won't set the 'h0--hormal' class to the heading
    'h0--bold': bold === true,
    'h0--normal': bold === false
  });

const collapsibleClasses = (
  size,
  tag,
  noBorder,
  background,
  active,
  metaContent
) =>
  classNames({
    'b-collapsible': true,
    'b-collapsible--tag': tag,
    'b-collapsible--active': active,
    'b-collapsible--background': background,
    'b-collapsible--medium': size === 'medium',
    'b-collapsible--small': size === 'small',
    'b-collapsible--tiny': size === 'tiny',
    'b-collapsible--no-border': noBorder,
    'b-collapsible--columns': metaContent
  });

const contentClasses = (smallContent, metaContent) =>
  classNames({
    'b-collapsible__content': true,
    'b-collapsible__content--small': smallContent,
    'b-collapsible__content--meta': metaContent
  });

const Collapsible = ({ 
  heading,
  subheading,
  subheadingContent,
  metaContent,
  date,
  dateFrom,
  dateTo,
  h,
  smallContent,
  tag,
  category,
  noBorder,
  alert,
  code,
  id,
  bold,
  arrowLeft,
  archiveLink,
  type,
  children,
  size = 'large',
  collapsed: collapsedProp = false,
  background = false
}) => {
  const [collapsed, setCollapsed] = useState(false);
  const parentElement = useRef(null);

  useEffect(() => {
    if (
      window.location.hash.slice(1, window.location.hash.length) === id
    ) {
      setCollapsed(true);
    }
  }, [id]);

  useEffect(() => {
    if (collapsed && !collapsedProp) {
      const newHash = `#${id}`;

      // When collapsed, add hash to url
      window.history.replaceState(null, null, newHash);
      return;
    }
    // Clean hash
    // eslint-disable-next-line
  }, [collapsed, collapsedProp]);

  useEffect(() => {
    setCollapsed(collapsedProp);
    // eslint-disable-next-line
  }, [collapsedProp]);

  const headingSelector = () => {
    if (h) return h;
    else if (size === 'large') return 'h2';
    else if (size === 'medium') return 'h3';
    else if (size === 'small') return 'h4';
    else if (size === 'tiny') return 'h5';
  };

  return React.createElement(
    type || 'div',
    {
      className: collapsibleClasses(
        size,
        tag,
        noBorder,
        background,
        collapsed,
        metaContent
      ),
      ref: parentElement,
      'data-parent-id': id
    },
    <>
      {category && (
        <div className="b-collapsible__category">{category}</div>
      )}
      <Heading h={headingSelector()} className={headingClasses(size)}>
        <button
          className={`${buttonClasses(collapsed, size, tag, null, null, arrowLeft)} ${
            bold ? 'h0--bold' : ''
          }`}
          aria-expanded={collapsed}
          aria-controls={id}
          onClick={e => setCollapsed(!collapsed)}
        >
          <span>{heading}</span>
          {code && (
            <span className="b-collapsible__code">{code}</span>
          )}
        </button>
      </Heading>
      {tag &&
        (tag.description ? (
          <button
            className="b-collapsible__tag"
            aria-expanded="false"
            aria-controls={`tag-${id}`}
          >
            <span>{tag.title}</span>
          </button>
        ) : (
          <div className="b-collapsible__tag b-collapsible__tag--no-action">
            <span>{tag.title}</span>
          </div>
        ))}
      {tag && tag.description && (
        <div className="b-collapsible__tag-content" id={`tag-${id}`}>
          {tag.description}
        </div>
      )}

      {dateFrom || dateTo ? (
        <div className="b-collapsible__meta-date b-collapsible__meta-date--from-to">
          {dateFrom && (
            <div className="b-collapsible__meta-date-from">
              Gyldig fra: {dateFrom}
            </div>
          )}
          {dateTo && (
            <div className="b-collapsible__meta-date-to">
              Gyldig til: {dateTo}
            </div>
          )}
        </div>
      ) : null}

      {subheading && !subheadingContent && (
        <div className="b-collapsible__subheading l-mt-1">
          {subheading}
        </div>
      )}

      {!subheading && subheadingContent && (
        <div className="l-mt-1"> {subheadingContent}</div>
      )}
      {subheading &&
        subheadingContent &&
        (collapsed ? (
          <Collapsible
            heading={subheading}
            tag={Boolean(subheadingContent)}
            size="small"
            bold={bold}
            smallContent
          >
            <p>{subheadingContent}</p>
          </Collapsible>
        ) : (
          <p className="b-collapsible__meta-heading">{subheading}</p>
        ))}
      {!collapsed && alert && (
        <div className="l-mt-1">
          <Alert status="success" small>
            {alert}
          </Alert>
        </div>
      )}

      {metaContent ? (
        <div className="b-collapsible__meta-content-mobile">
          {metaContent}
        </div>
      ) : null}

      <div
        id={id}
        aria-hidden={!collapsed}
        hidden={!collapsed}
        className={contentClasses(smallContent, metaContent)}
      >
        {children}

        {archiveLink && (
          <p>
            <a href="#!" className="b-collapsible__link">
              Se tidligere utgaver
            </a>
          </p>
        )}
      </div>

      {metaContent ? (
        <div className="b-collapsible__meta-content">{metaContent}</div>
      ) : null}

      {background && collapsed && (
        <div className="b-collapsible__footer">
          {metaContent ? (
            <button
              className={buttonClasses(collapsed, false, false, false, true)}
              aria-expanded={collapsed}
              aria-controls={id}
              onClick={e => setCollapsed(!collapsed)}
            >
              Neste
            </button>
          ) : null}

          <button
            className={buttonClasses(collapsed, 'secondary', false, true)}
            aria-expanded={collapsed}
            aria-controls={id}
            onClick={e => setCollapsed(!collapsed)}
          >
            Lukk
          </button>

          {date ? (
            <div className="b-collapsible__meta-date">
              {date}
              <span className="hide-mobile">&nbsp; | &nbsp;</span>
              <a href="#!" className="b-page-meta__link">
                Se tidligere versjoner
              </a>
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};

Collapsible.propTypes = {
  heading: PropTypes.string,
  subheading: PropTypes.string,
  subheadingContent: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  metaContent: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  date: PropTypes.string,
  dateFrom: PropTypes.string,
  dateTo: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'tiny']),
  h: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  smallContent: PropTypes.bool,
  tag: PropTypes.object,
  category: PropTypes.string,
  noBorder: PropTypes.bool,
  alert: PropTypes.string,
  collapsed: PropTypes.bool,
  code: PropTypes.string,
  id: PropTypes.string,
  bold: PropTypes.bool,
  background: PropTypes.bool,
  arrowLeft: PropTypes.bool,
  archiveLink: PropTypes.bool,
  type: PropTypes.string,
  children: PropTypes.node
};

export default Collapsible;
