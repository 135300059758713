import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import ItemsList from './utils/ItemsList';
import classNames from 'classnames';
import URLSearchParams from '@ungap/url-search-params';
import useInterval from './utils/useInterval';
import { debounce } from 'lodash';

import stripStringForHtmlUtil from './utils/stripStringForHtmlUtil';

// THIS IS FOUL!!!! This is also the reason why search is borked locally. TODO: fetch proper paths from backend.
const searchPageUrl = "/search"
const searchSuggestionUrl = "/_/service/helsedirektoratet/autocomplete"

const renderSuggestionsContainer = ({
  containerProps: { role, ...otherContainerProps },
  children
}) => (
  <div {...otherContainerProps} id={undefined}>
    {React.Children.map(children, child => (
      <ItemsList {...child.props} id={otherContainerProps.id} />
    ))}
  </div>
);

const renderSuggestion = suggestion => (
  <div>
    {suggestion.title && (
      <div className="suggestion-title">
        {suggestion.title}{' '}
        {suggestion.category && (
          <div className="suggestion-category">{suggestion.category}</div>
        )}
        {suggestion.file && (
          <div className="suggestion-download">{suggestion.file}</div>
        )}
      </div>
    )}
    {suggestion.intro && (
      <div className="suggestion-intro">{stripStringForHtmlUtil(suggestion.intro)}</div>
    )}
    {suggestion.topic && (
      <div className="suggestion-topic">{suggestion.topic}</div>
    )}
  </div>
);

const InputSearch = ({   
  id,
  label,
  biggerLabel,
  dark,
  autoFocus,
  fnChange,
  fnClear,
  customTrigger,
  showSuggestions = true,
  exampleSuggestions = []
}) => {
  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [placeholderIndex, setPlaceholderIndex] = useState(0);
  const inputElement = useRef(null);

  /* We are using callback to make throtte work in this component function */
  const delayedSuggestionsFetchRequested = useCallback(debounce((value) => onSuggestionsFetchRequested(value), 200), []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const searchQuery = urlParams.get('searchquery');
    setValue(searchQuery ? searchQuery : '')
  }, []);

  useInterval(() => {
    setPlaceholderIndex(
      placeholderIndex < exampleSuggestions.length - 1
        ? placeholderIndex + 1
        : 0
    );
  }, 2000);

  const renderInputComponent = inputProps => (
    <div className="b-input-search">
      {label && (
        <h2>
          <label
            id="search-input-label"
            htmlFor={id}
            className={classNames({
              'b-input-search__label': true,
              'b-input-search__label--dark': dark,
              'b-input-search__label--large': biggerLabel
            })}
          >
            {label}
          </label>
        </h2>
      )}
      <div className="b-input-search__inputs">
        {!value && (
          <div className="b-input-search__placeholder" aria-hidden>
            {exampleSuggestions.length
              ? exampleSuggestions[placeholderIndex]
              : ''}
          </div>
        )}
        <input
          title="Søk"
          aria-labelledby="search-input-label"
          id={id}
          {...inputProps}
          autoFocus={autoFocus}
          className={classNames({
            'b-input-search__field': true,
            'b-input-search__field--dark': dark
          })}
        />
        {value && (
          <button
            className="b-input-search__button b-input-search__clear"
            type="button"
            aria-label="Lukk søkeresultater"
            onClick={triggerClear}
          ></button>
        )}
        <button
          className="b-input-search__button"
          type="button"
          aria-label="Søk"
          onClick={triggerSearch}
        />
      </div>
    </div>
  );

  function triggerSearch() {
    if(customTrigger) {
      return customTrigger()
    }

    if (!showSuggestions && fnChange) {
      return;
    }
    const encodedValue = encodeURIComponent(value);
    window.location = `${searchPageUrl}?searchquery=${encodedValue}`;
  }

  function triggerClear() {
    setValue('')
    if (!fnClear) {
      return;
    }
    fnClear('');
  }

  function onChange(event, { newValue }) {
    if (!showSuggestions && fnChange) {
      fnChange(newValue)
    }
    setValue(newValue);
  }

  function onSuggestionSelected(event, { suggestion }) {
    // Last row clicked, don't send GTM tag
    if (suggestion.skip) {
      window.location = suggestion.url;
    }
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'autosuggest',
      'category': 'Autosuggestion click',
      'action': suggestion.url,
      'label': value,
      'value': suggestion.index
    });
    window.location = suggestion.url;
  }

  // Autosuggest will call this function every time you need to update suggestions.
  const onSuggestionsFetchRequested = ({ value }) => {
    if (!showSuggestions) {
      return;
    }
    if (value && value.length >= 3) {
      const encodedValue = encodeURIComponent(value);
      fetch(`${searchSuggestionUrl}?searchQuery=${encodedValue.toLowerCase()}`)
        .then(res => res.json())
        .then(data => {
          // Adding indexes to suggestions, so we know where in the list the item is
          const suggestionsWithIndexes = data.map((item, index) => ({index: index + 1, ...item}));
          if (suggestionsWithIndexes.length) {
            setSuggestions([
              ...suggestionsWithIndexes,
              {
                // The last row that is highlighted
                skip: true,
                ...suggestionsWithIndexes[data.length - 1],
                title: value,
                category: '',
                file: '',
                topic: '',
                intro: `Se alle resultater for "${value}"`,
                url: `${searchPageUrl}?searchquery=${value}`
              }
            ]);
          } else {
            setSuggestions([]);
          }
        })
        .catch(ex => {
          setSuggestions([]);
        });
    } else {
      setSuggestions([]);
    }
  }

  const inputProps = {
    value: value,
    onChange: onChange,
    ref: inputElement,
    role: 'combobox',
    'aria-expanded': suggestions.length > 0,
    onKeyDown: event => {
      // 13 = enter key
      if (event.keyCode === 13 && value) {
        // If the search query is not equal to any of the suggestions, we go to the SERP
        // We also want to ignore the last suggestion, since it is not really a suggestion
        if (
          !suggestions.some((x, index) => {
            if (index !== suggestions.length - 1) {
              return x.title === value;
            } else return false;
          })
        ) {
          triggerSearch();
        }
      }
    }
  };

  const containerProps = {
    role: null,
    'aria-haspopup': null,
    'aria-owns': null,
    'aria-expanded': null
  };

  return (
    <Autosuggest
      suggestions={showSuggestions ? suggestions : []}
      onSuggestionsFetchRequested={delayedSuggestionsFetchRequested}
      onSuggestionsClearRequested={() => setSuggestions([])}
      onSuggestionSelected={onSuggestionSelected}
      renderInputComponent={renderInputComponent}
      getSuggestionValue={suggestion => suggestion.title}
      renderSuggestionsContainer={renderSuggestionsContainer}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
      containerProps={containerProps}
    />
  );
};

InputSearch.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  biggerLabel: PropTypes.bool,
  dark: PropTypes.bool,
  showSuggestions: PropTypes.bool,
  autoFocus: PropTypes.bool,
  fnChange: PropTypes.func,
  fnClear: PropTypes.func,
  customTrigger: PropTypes.func,
  exampleSuggestions: PropTypes.arrayOf(PropTypes.string)
};

export default InputSearch;
