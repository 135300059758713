import React from "react";

const ChevronIcon = ({rotate=0, ...rest}) => {
  return (
    <svg style={{ transform: `rotate(${rotate}deg)` }} width="100%" height="100%" viewBox="0 0 29 17" version="1.1" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g id="Page-1" stroke="none" strokeWidth="2" fill="none" fillRule="evenodd">
        <g id="Nivå-3-Underkapittel-ekspandert-2.2-eks-Copy" transform="translate(-1272.000000, -494.000000)" stroke="#0667C6" strokeWidth="2">
          <polyline id="Rectangle-7-Copy-13" transform="translate(1286.435029, 509.435029) rotate(-135.000000) translate(-1286.435029, -509.435029) " points="1295.93503 499.935029 1295.93503 518.935029 1276.93503 518.935029"></polyline>
        </g>
      </g>
    </svg>
  )
}

export default ChevronIcon
